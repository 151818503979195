import {reactive, toRefs, defineComponent, getCurrentInstance} from 'vue';
import language from './reloadcacheLanguage'
export default defineComponent({
    name: "cacheList",
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        proxy.addLangProps(language);
        let dataObj=reactive({
            pageListRef:null as any,
            canReload:false, //是否有重载缓存权限，顶部按钮通过后台控制，但是前端每行的缓存重载按钮怎么控制呢，就由该变量控制
            pageList: {
                idField:'F_REGION',
                title:proxy.$t('reloadcache.title'),
                // dragRow:true,//是否可以拖动表格
                // showRadio:true,//是否显示radio，最好是和multiple相反
                multiple:true,
                isShowQueryParam:false,
                canPage: false,
                modelMethod: utils.Api.buildUrl("/reloadcache/pageData")
            }
        })
        const doReload=(regions:any)=>{
            utils.Tools.configBox({
                message:proxy.$t('reloadcache.reloadTip'),
                sureFn: async ()=> {
                    const loading = proxy.$loading({lock: true,text: proxy.$t('loadMsg'),spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.7)'});
                    let res = await utils.Api.reloadCache({regions: regions});
                    loading.close();
                    utils.Tools.success({message: res.msg});
                }
            });

        }
        //多条缓存重载
        const reloadHandler=async()=> {
            let selectRows = dataObj.pageListRef.getTbInst().getSelection();
            if (selectRows.length == 0) {
                proxy.$message(proxy.$t('reloadcache.selectTip'));
                return;
            }
            selectRows = selectRows.map((item:any)=>{
                return item.F_REGION;
            });
            doReload(selectRows);
        }
        //单条缓存重载
        const reloadBuffer=(row:any)=>{
            dataObj.pageListRef.getTbInst().toggleRowSelection(row);
            doReload([row.F_REGION]);
        }
        //表格加载完毕事件
        const gridLoaded=(res:any)=>{
            dataObj.canReload=res.canReload;
        }
        return{
            ...toRefs(dataObj),doReload,reloadHandler,reloadBuffer,gridLoaded//,myLinkHandler
        }
    }
});